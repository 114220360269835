/** @format */

import React from "react";
import Encounter from "./Encounter";
import Combatants from "./Combatants";
import { withHelper } from "./helpers";

import "./css/reboot.css";
import "./css/index.css";
import "./css/overlay.css";

class OverlayRaw extends React.Component {
  state = {
    limitBreak: 0,
    discordData: [],
  };
  handleLimitBreak = (value) => {
    this.setState({ limitBreak: value });
  };
  componentWillReceiveProps(nextProps) {
    if (Object.getOwnPropertyNames(this.props.Combatant).length === 0)
      return false;

    let maxRows = this.props.config.maxCombatants;
    let dataArray = Object.keys(this.props.Combatant);
    let battler = dataArray.slice(0, maxRows);
    let combatant;
    let discordData = [];

    for (const ref in battler) {
      combatant = this.props.Combatant[battler[ref]];

      // Send to Discord the right name in Settings
      if (combatant.name.toUpperCase() === "YOU")
        combatant.name = this.props.config.characterName;

      // Send limit break data separated
      if (combatant.name.toLowerCase() === "limit break") {
        this.handleLimitBreak(
          parseInt(
            (this.props.Combatant.damage / this.props.Encounter.damage) * 100,
            10
          )
        );
        break;
      }

      discordData.push({
        job: combatant.Job,
        characterName: combatant.name,
        dps: combatant.ENCDPS,
        damage: parseInt(
          (combatant.damage / this.props.Encounter.damage) * 100,
          10
        ),
        hps: combatant.ENCHPS,
        healed: combatant["healed%"],
        deaths: combatant.deaths,
        crit: combatant["crithit%"],
        dhit: combatant.DirectHitPct,
        // maxhit: combatant.maxhit.split('-')
      });
    }
    this.setState({ discordData });
  }
  render() {
    const props = this.props;
    return (
      <div
        className={`damage-meter${props.isActive ? "" : " inactive"}${
          props.config.locale === "zhCN" || props.config.locale === "zhHK"
            ? " chinese"
            : ""
        }`}
        onContextMenu={props.openConfig}
        style={{
          transform: `scale(${props.config.zoom})`,
          transformOrigin: "50% 0 0",
        }}
      >
        <Combatants
          data={props.Combatant}
          encounterDamage={props.Encounter.damage}
          config={props.config}
        />
        <Encounter
          {...props.Encounter}
          limitBreak={this.state.limitBreak}
          discordData={this.state.discordData}
          config={props.config}
        />
      </div>
    );
  }
}

const Overlay = withHelper({ WrappedComponent: OverlayRaw });
export default Overlay;
